import { isNumber } from "lodash"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const customerKeysToLabel = (data) => {
    const keyedData = _.mapKeys(data, (value, key) => {
        let index = key

        if (index == 'latitude') {
            index = 'GPS_lat'
        }

        if (index == 'longitude') {
            index = 'GPS_long'
        }

        index = index.replaceAll('_', ' ')

        index = _.startCase(index)

        if (index == 'Consumer Name Arabic') {
            index = 'Consumer Name (Arabic)'
        }

        if (index == 'Cpr No') {
            index = 'CPR No'
        }
        if (index == 'Cr No') {
            index = 'CR No'
        }

        if (index == 'Po Box') {
            index = 'PO Box'
        }

        return index
    })

    let url = keyedData.Image

    if (url != 'https://source.unsplash.com/random') {
        const imageName = keyedData.Image.split('.')[0]
        const imageExt = keyedData.Image.split('.').pop()
        url = `https://momaa.live/api/assets/images/customers/${imageName}-400.${imageExt}`
    }

    keyedData.Image = [
        {
            url,
        },
    ]

    keyedData.showImage = url

    return keyedData
}

export const customerGetLabelForKey = (key) => {
    const mapping = {
        'well_no': 'Well No',
        'reference_no': 'Reference No',
        'consumer_name': 'Consumer Name',
        'consumer_type': 'Consumer Type',
        'aquifers': 'Aquifers',
        'water_source': 'Water Source',
        'pump_type': 'Pump Type',
        'cpr_no': 'CR/CPR No',
        'building': 'Building',
        'plot_no': 'Plot No',
        'area': 'Area',
        'governorate': 'Governorate',
        'po_box': 'PO Box',
        'mobile': 'Mobile',
        'email': 'Email',
        'office_phone': 'Office Phone',
        'serial_no': 'Serial No',
        'meter_size': 'Meter Size',
        'meter_model': 'Meter Model',
        'gps': 'GPS',
        'water_quality': 'Water Quality',
        'image': 'Image',
        'address': 'Address',
        'status': 'Status',
    }

    return mapping.hasOwnProperty(key) ? mapping[key] : 'N/A';
}


export const getDateRange = (startTime, endTime = null, $period) => {
    if (!endTime) {
        return startTime.format('dd/mm/YYYY');
    }

    if (startTime.isSame(endTime, period)) {
        return startTime.format('dd/mm/YYYY');
    }

    return startTime.format('dd/mm/YYYY') + ' - ' + endTime.format('dd/mm/YYYY')
}

export const bcMul = (firstValue, secondValue, scale) => {
    if (isNaN(firstValue) || isNaN(secondValue)) {
        return null;
    }
    return parseFloat(firstValue * secondValue).toFixed(scale);
}

export const showErrorToast = (toast, response, defaultMessage = 'Something Went Wrong') => {
    getErrorMessage(response)
        .then((message) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
        .catch(error => {
            console.log(error);
            toast({
                component: ToastificationContent,
                props: {
                    title: defaultMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
}

const getErrorMessage = (response) => {
    return new Promise((resolve, reject) => {

        if (!response.hasOwnProperty('status') || (response.status != 400 && response.status != 401 && response.status != 403)) {
            reject('Unknown error response');
        }

        const data = response.data;
        if (data instanceof Blob) {
            const blob = new Blob([data], { type: "application/json" });
            const file = new File([blob], 'any', { type: blob.type })
            const reader = new FileReader();

            reader.onload = () => resolve(JSON.parse(reader.result).message);
            reader.onerror = reject
            reader.readAsText(file)
        }
        else {
            resolve(data.message)
        }
    });
}